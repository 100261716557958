import { Injectable, NgZone } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  loginSub = new BehaviorSubject(``);
  twoFA = new BehaviorSubject(``);
  permission = new BehaviorSubject({});
  loginObs = this.loginSub.asObservable();
  code: string;
  httpOptions: { headers: HttpHeaders; };

  // public baseUrl = "https://javavesting.fieres.io/" //client server
  // public baseUrl = "http://172.16.6.130:2063/api/v1/" //local server 
  public baseUrl = "https://node-vestingnew.mobiloitte.io/api/v1/" //domain server
  public websiteURL = window.location.protocol + "//" + window.location.host + "/"
  constructor(public http: HttpClient, private toastr: ToastrService, private spinner: NgxSpinnerService, public routes: Router,
    private zone: NgZone
  ) {

  }


  getServerSentEvent(url: string): Observable<any> {
    return new Observable(observer => {
      const eventSource = new EventSource(this.baseUrl + url);

      eventSource.onmessage = (event) => {
        this.zone.run(() => {
          observer.next(JSON.parse(event.data));
        });
      };

      eventSource.onerror = (error) => {
        this.zone.run(() => {
          observer.error(error);
        });
      };

      return () => {
        eventSource.close();
      };
    });
  }

  // Header Managment 
  changeLoginSub(msg) {
    this.loginSub.next(msg);
  }

  // Api Functionlity 
  // Api Structuring Functionality 
  post(url, data) {
    if (localStorage.getItem('Auth')) {
      this.code = localStorage.getItem('Auth') || localStorage.getItem("data")
    }
    if (localStorage.getItem('data') || localStorage.getItem('Auth')) {
      this.httpOptions = {
        headers: new HttpHeaders({ 'token': `${this.code}` })
      };
    }
    return this.http.post(this.baseUrl + url, data, this.httpOptions);
  }
  put(url, data) {
    if (localStorage.getItem('Auth')) {
      this.code = localStorage.getItem('Auth') || localStorage.getItem("data")
    }
    if (localStorage.getItem('data') || localStorage.getItem('Auth')) {
      this.httpOptions = {
        headers: new HttpHeaders({ 'token': `${this.code}` })
      };
    }
    return this.http.put(this.baseUrl + url, data, this.httpOptions);
  }

  get(url) {
    if (localStorage.getItem('Auth')) {
      this.code = localStorage.getItem('Auth') || localStorage.getItem("data")
    }
    if (localStorage.getItem('data') || localStorage.getItem('Auth')) {
      this.httpOptions = {
        headers: new HttpHeaders({ 'token': `${this.code}` })
      }
    }
    return this.http.get(this.baseUrl + url, this.httpOptions);
  }

  // delete
  delete(url) {
    if (localStorage.getItem('Auth')) {
      this.code = localStorage.getItem('Auth') || localStorage.getItem("data")
    }
    if (localStorage.getItem('data') || localStorage.getItem('Auth')) {
      this.httpOptions = {
        headers: new HttpHeaders({ 'token': `${this.code}` })
      }
    }
    return this.http.delete(this.baseUrl + url, this.httpOptions);
  }

  // Form Data Api Structure
  postApi(endPoint, data): Observable<any> {
    if (localStorage.getItem('Auth') || localStorage.getItem('data')) {
      this.code = localStorage.getItem('Auth') || localStorage.getItem("data")
    }
    if (localStorage.getItem('data') || localStorage.getItem('Auth')) {

      this.httpOptions = {
        headers: new HttpHeaders({ 'token': `${this.code}` })
      }
    }
    return this.http.post(this.baseUrl + endPoint, data, this.httpOptions);
  }

  removeEmptyKeys(obj) {
    for (let key in obj) {
      if (obj[key] === "" || obj[key] === null || obj[key] === undefined || (Array.isArray(obj[key]) && obj[key].length === 0) || (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)) {
        delete obj[key];
      }
    }
    return obj;
  }

  bodyToData(body) {
    let formdata = new FormData()
    for (const key in body) {
      formdata.append(key, body[key])
    }
    return formdata
  }
  // Spinner 
  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  // Toaster Functionality
  toasterSucc(msg) {
    this.toastr.success(msg)
  }
  toasterErr(msg) {
    this.toastr.error(msg)
  }
  toasterInfo(msg) {
    this.toastr.info(msg)
  }

  //Export
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    const londonDate = new Date().toLocaleString('en-GB', { timeZone: 'Europe/London' });
   
    FileSaver.saveAs(data, fileName + '_export_' + londonDate + EXCEL_EXTENSION);
  }

  // Logout
  onLogout() {
    localStorage.clear();
    // window.location.reload();
    this.routes.navigate(['/login']);
    // $('#signout_modal').modal('hide');
  }

  // prevent space
  // preventSpace(event) {
  //   if (event.charCode == 32 && !event.target.value) {
  //     event.preventDefault()
  //   }
  // }
  /** to prevent first space */
  preventSpace(event) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  isShow(permissionArr, managementName, permName) {
    if (!Object.keys(permissionArr).length) {
      return true
    }
    else {
      if (permissionArr[managementName]?.includes(permName)) {
        return true
      }
      else {
        return false
      }
    }
  }
  // get IP Address
  public getIPAddress() {
    return this.http.get("https://jsonip.com/?format=json");
  }
}
