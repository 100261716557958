import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { countryJson } from 'src/assets/country';
import { contractHelper } from 'src/assets/js/contractHelper';
declare var Web3
// import web3 from 'web3/lib/commonjs/web3.js';

// const Web3 = new web3(new web3.providers.HttpProvider("https://rpc2.fieroscan.com/"))
declare var $
declare let window: any;
@Component({
  selector: 'app-token-trnsaction-detail',
  templateUrl: './token-trnsaction-detail.component.html',
  styleUrls: ['./token-trnsaction-detail.component.css']
})
export class TokenTrnsactionDetailComponent implements OnInit {
  @Input() userId: any
  userForm: FormGroup;
  currTab: any = "Withdraw";
  withdrawlData: any = [];
  pageNumber: number = 1;
  itemsPerPage: number = 10;
  depositeData: any = [];
  totalRecords: any
  coin: any;
  coinName: any;
  coinData: any = [];
  url: string;
  userDetail:any
  walletBalance: any = [];
  isSearched: boolean = false;
  id: any;
  documentId: any;
  permissionArr : any = {}
  kycId: any;
  today: any = new Date().toISOString().split('T')[0]
  viewData: any;
  constructor(private router: Router, public service: MainService, private route: ActivatedRoute,private date: DatePipe) {
    this.route.params.subscribe((params) => {
      this.id = params.id

    })
    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res
        console.log(res);

      }
    })
    this.service.twoFA.subscribe((res: any) => {
      this.twoFAStatus = res;
    });
  }

  ngOnInit(): void {
    // this.withdrawl();
    
    this.userForm = new FormGroup({
      startdate: new FormControl(""),
      enddate: new FormControl(""),
      searchText: new FormControl(""),
      country: new FormControl(""),
      userStatus: new FormControl(""),
      ramdomId: new FormControl("")
    });
    this.coinList()
    this.myProfile();
    // this.getClaimData();
    this.countryList();
  }
   products: any = [];
  countryList() {
    let obj = new countryJson()
    this.products = obj.country
  }

  sortType : any = "DESC"
  type : any = "DATE"

  coinList() {

    let startdate = this.userForm.value.startdate;

    let enddate = this.userForm.value.enddate;
    // let startdate = this.userForm.value.startdate;

    // let enddate = this.userForm.value.enddate;

    var search = this.userForm.value.searchText;
    let ramdomId = this.userForm.value.ramdomId;
    let url = `wallet/transactions?page=${this.pageNumber }&pageSize=${this.itemsPerPage}${this.userForm.value.searchText ? "&userEmail=" + search : ""}${this.userForm.value.startdate ? "&fromDate=" + startdate : ""}${this.userForm.value.enddate ? "&toDate=" + enddate : ""}${this.userForm.value.userStatus ? "&status=" + this.userForm.value.userStatus : ""}${this.userForm.value.ramdomId ? "&randomId=" + ramdomId : ''}&type=BUY_TOKEN&txnType=FULL_CLAIM`;
    url += `&sortKey=${this.type}&sortType=${this.sortType}`
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      console.log('df', res);
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.coinData = res['data']['docs']
        this.totalRecords = res['data']['total']
      }else{
        this.coinData = []

      }

    }, (err) => {
      this.coinData = []
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr(err.error.message);
      }
    })

  }

  // Api of search 
  search() {
    this.coinName = this.userForm.value.select;
    // let fromDate=Date.parse(this.userForm.value.startdate);
    let fromDate = Date.parse(this.userForm.value.startdate);
    let currentTime = new Date().getTime()
    console.log("hdh", fromDate);

    let toDate = Date.parse(this.userForm.value.enddate);
    console.log('jhhj', toDate + currentTime, currentTime);

    if (this.userForm.value.enddate && this.coinName && this.currTab === 'Withdraw') {
      this.url = "wallet/admin/transaction-history/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&coinName=" + this.coinName + "&fromDate=" + fromDate + "&toDate=" + toDate + "&txnType=WITHDRAW"
    }

    else if (this.userForm.value.enddate && this.currTab === 'Withdraw') {
      this.url = "wallet/admin/transaction-history/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&fromDate=" + fromDate + "&toDate=" + toDate + "&txnType=WITHDRAW";
    }

    else if (this.userForm.value.startdate && this.currTab === 'Withdraw') {
      this.url = "wallet/admin/transaction-history/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&fromDate=" + fromDate + "&txnType=WITHDRAW";
    }

    else if (this.coinName && this.currTab === 'Withdraw') {
      this.url = "wallet/admin/transaction-history/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&coinName=" + this.coinName + "&txnType=WITHDRAW";
    }

    else if (this.userForm.value.enddate && this.coinName && this.currTab === 'Deposite') {
      this.url = "wallet/admin/transaction-history/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&coinName=" + this.coinName + "&fromDate=" + fromDate + "&toDate=" + toDate + "&txnType=DEPOSIT"
    }
    else if (this.userForm.value.enddate && this.currTab === 'Deposite') {
      this.url = "wallet/admin/transaction-history/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&fromDate=" + fromDate + "&toDate=" + toDate + "&txnType=DEPOSIT";
    }

    else if (this.userForm.value.startdate && this.currTab === 'Deposite') {
      this.url = "wallet/admin/transaction-history/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&fromDate=" + fromDate + "&txnType=DEPOSIT";
    }

    else if (this.coinName && this.currTab === 'Deposite') {
      this.url = "wallet/admin/transaction-history/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&coinName=" + this.coinName + "&txnType=DEPOSIT";
    }

    this.url += `&dType=${this.type}&sort=${this.sortType}`



    this.service.get(this.url).subscribe((res: any) => {
      console.log('search', res);

      if (res['status'] == 200) {
        this.service.toasterSucc(res.message);
        // this.withdrawlData=res['data']['resultlist']
        this.coinData = res['data']['resultlist']
        this.totalRecords = res['data']['totalCount']
      }else{
        this.coinData = []

      }

    }, (err) => {
      this.coinData = []
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr(err.error.message);
      }
    })
    this.service.hideSpinner();
  }

  // reset data of search
  reset() {
    // if (
    //   this.userForm.value.startdate ||
    //   this.userForm.value.enddate ||
    //   this.userForm.value.searchText ||
    //   this.userForm.value.ramdomId ||
    //   this.userForm.value.userStatus
    // ) {
    //   this.userForm.reset({
    //     startdate: "",
    //     userStatus: "",
    //     enddate: "",
    //     searchText: "",
    //     country: "",
    //     ramdomId: ""
    //   });
    //   this.coinList();
    // }
    this.userForm.reset({
      startdate: "",
      userStatus: "",
      enddate: "",
      searchText: "",
      country: "",
      ramdomId: ""
    });
    this.coinList();
  }

  //export User
  async exportAsXLSX() {
    let temp = [];
    let startdate = this.userForm.value.startdate;

    let enddate = this.userForm.value.enddate;
    // let startdate = this.userForm.value.startdate;

    // let enddate = this.userForm.value.enddate;

    var search = this.userForm.value.searchText;
    let ramdomId = this.userForm.value.ramdomId;
    let url = `wallet/transactions?page=${this.pageNumber }&pageSize=${this.itemsPerPage}${this.userForm.value.searchText ? "&userEmail=" + search : ""}${this.userForm.value.startdate ? "&fromDate=" + startdate : ""}${this.userForm.value.enddate ? "&toDate=" + enddate : ""}${this.userForm.value.userStatus ? "&status=" + this.userForm.value.userStatus : ""}${this.userForm.value.ramdomId ? "&randomId=" + ramdomId : ''}&type=BUY_TOKEN&txnType=FULL_CLAIM`;
    url += `&sortKey=${this.type}&sortType=${this.sortType}`
    this.service.showSpinner();
    try {
      let res: any = await this.service.get(url).toPromise();

      this.service.hideSpinner();
      if (res.status == 200) {
        let dataArr = [];
        res.data.docs.forEach((element, ind) => {
          dataArr.push({
            "S no": ind + 1,
            "Username": element.userId.firstName + " " +  element.userId.lastName ? element.userId.firstName + " " +  element.userId.lastName: "N/A",
            "Email Id": element.userId.email  ? element.userId.email  : "N/A",
            "Random Id": element.orderId ? element.orderId : "N/A",
            // "Claim Request": element.claimRequest ? element.claimRequest : "N/A",
            "Requested Token (FIERO)": element.buyAmount ? element.buyAmount : "N/A",
            "Date & Time": element.createdAt ? this.date.transform(element.createdAt, 'medium') : "N/A",
            "Status": element.transactionStatus ? element.transactionStatus : "N/A",
          });
        });

        this.service.exportAsExcelFile(dataArr, "Token Claim Transaction list "+ this.today);
      }
    } catch (error) {
      console.log(error)
      this.service.hideSpinner();
      this.service.toasterErr(error.error.message);
    }


  }



  // wallet balance
  // api of Trading
 
  
  pagination(page) {
    this.pageNumber = page;
      this.coinList();
  }
  tokenId: any
  tokenIdReject: any
  approved(id) {
    this.tokenId = id
    $("#approveModal").modal("show");
  }
  mfa = false
  params : any
  twoFAStatus : any
  twoFAStatusFun(id, item){
    this.params = {
       id
    }
    this.viewData = item
    if(this.twoFAStatus == 'NONE'  || this.twoFAStatus == 'SKIP'){
      this.approved(id)
    }
    else{
      // $('#googleAuth').modal({ backdrop: 'static' });
     this.mfa = true
    }
  }
  verfyAuth(params){
    this.approved(this.params.id)
  }
  isLoading = false
  approveKyc() {
    this.connectToMetaMask(this.viewData.userId.withdrawAddress)
  }
  async connectToMetaMask(inbuiltAddress) {
    if (window.ethereum) {
      this.isLoading = true
      try {
        const chainId = 1001
        const web3 = new Web3(window.ethereum);
        if (window.ethereum.networkVersion !== chainId) {
          try {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: web3.utils.toHex(chainId) }]
            });
            this.doTrnasaction(inbuiltAddress)
          } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
              
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainName: 'FIERO',
                    chainId: web3.utils.toHex(chainId),
                    nativeCurrency: { name: 'FIERO', decimals: 18, symbol: 'FIERO' },
                    rpcUrls: ['https://fieroscan.com/']
                  }
                ]
              });
              this.doTrnasaction(inbuiltAddress)
            }
            else{
              this.service.toasterErr(err.error.message)

              console.log(err);
              this.isLoading = false
            }
          }
        }
      } catch (error) {
        this.service.toasterErr(error.message)

        console.log(error);
        this.isLoading = false
        return
      }
     
      
    } else {
      this.isLoading = false
      console.error('MetaMask not found');
      this.service.toasterErr('MetaMask not found')
    }
  }
  async doTrnasaction(inbuiltAddress){
    try {
      // Request account access
      let isConnected = await window.ethereum.request({ method: 'eth_requestAccounts' });
      console.log('MetaMask connected', isConnected);
      
      const web3 = new Web3(window.ethereum);
      const constant = new contractHelper()
      const newVestingContract = await new web3.eth.Contract(constant.nodeContractDeployAbi, this.viewData.planId.contractAddress);
      const gasEstimate = await newVestingContract.methods.claimTokens(
        inbuiltAddress, this.viewData.paymentId
      ).estimateGas({ from: isConnected[0] });
      const txObject = {
        from: isConnected[0],
        to: this.viewData.planId.contractAddress,
        gasPrice: Web3.utils.toHex('4000000000'),    // Always in Wei
        gasLimit: Web3.utils.toHex(gasEstimate),
        // value: Web3.utils.toWei(this.viewData.buyAmount.toString(), "ether"),
      };
      // const txHash = await window.ethereum.request({ method: 'eth_sendTransaction', params: txObject });
      const txHash = await newVestingContract.methods.claimTokens(
        inbuiltAddress, this.viewData.paymentId
      ).send(txObject)
      console.log('Transaction sent:', txHash.transactionHash);
      this.updateTransaction(txHash.transactionHash)
    } catch (error) {
      this.isLoading = false
      console.error(error);
      this.service.toasterErr(error.message)
    }
  }
  updateTransaction(txnHash) {
    var url =
      "wallet/updateTransaction";

    const data = {
      _id: this.userId,
      transactionHash: txnHash
    }
    this.service.post(url, data).subscribe(
      (res: any) => {
        console.log("view", res);
        this.isLoading = false
        if (res.status == 200) {
          this.coinList()
         
        }
      },
      (err) => {
        this.isLoading = false
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access.");
        } else {
          this.service.toasterErr("Something went wrong.");
        }
      }
    );
  }
  rejectTokenRequet() {
    this.service.showSpinner();
    const url = `wallet/vesting-management/admin/claim-token?tokenRequest=REJECTED&txnId=${this.tokenIdReject}`
    this.service.put(url, {}).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        this.service.toasterSucc("Rejected successfully.");
        $("#rejectTokenModal").modal("hide");
        this.coinList();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message);
      }
    },
      (error) => {
        this.service.hideSpinner();
      }
    );
  }
  toFixed(number, toLength: number) {
    return Number(number).toFixed(toLength)
  }
  // My Profile Functionality
  myProfile() {
    var url = 'admin/getProfile';
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userDetail = res['data']
        this.twoFAStatus = res.data.twoFAStatus
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Session Expired');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })
  }
  userDetailData:any
  functionNew(){
    return this.service.toasterErr('This feature is only capable for admin!')
  }
  functionNewReject(){
    return this.service.toasterErr('This feature is only capable for admin!')
  }
  getClaimData(){
    let url = `wallet/vesting-management/admin/get-claim-data`
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userDetailData = res['data']
      } else {
        // this.service.toasterErr(res['message']);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Session Expired');
      } else {
        // this.service.toasterErr(err.error.message);
      }
    })
  }
}
